import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import Banner from '../../assets/images/cloud-private-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from '../storage/index.module.less';
import Button from '../../components/Button';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';
import { cls } from '../../utils';

export const NICE_LIST = [
  {
    title: '超强AI算力',
    desc: '最高可提供6TOPS边缘算力，支持H.264/H.265 硬件编解码，32路1080P@30FPS硬件解码能力，16路1080P@30FPS硬件编码能力。',
  },
  {
    title: '丰富的扩展接口',
    desc: '前置千兆网口、万兆网口、串口、HDMI 、CAN、SDI、GPIO；后置USB接口、调试串口、管理网口、麦克、喇叭接口。',
  },
  {
    title: '自然散热技术',
    desc: '无无风扇全金属导冷设计，无音无尘；从架构到硬件均采用低功耗设计，典型功耗仅12W。',
  },
  {
    title: '高可靠',
    desc: '电路板三防加固，防振、防尘、防水（IP54），抗电磁干扰。',
  },
  {
    title: '多模式通信',
    desc: '灵活适配千兆网、WiFi、5G等多种网络连接方式，采用多模兼容设计，实现多网络无缝衔接。',
  },
  {
    title: '硬加密',
    desc: '采用硬件加密方式，支持SM2/SM3/SM4国密算法。',
  },
];

export default function CloudPrivatePage() {
  const [machActive, setMachActive] = useState(1);
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        title="边缘计算微服务器"
        desc="边缘计算微服务器是部署在网络边缘的计算设备，旨在快速处理来自附近数据源的大量实时数据，减少延迟，提高响应速度。它通过本地化的数据处理和分析，为物联网、自动驾驶、远程医疗等应用提供高效、可靠的服务支持。"
      />
      <SectionWrap
        title="土星云边缘计算微服务器"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.machContainer}>
          <div className={styles.machSwiper}>
            <div className={styles.imgViewCard}>
              <img src={`/saturn-product/se110r-cs/${machActive}.jpg`} alt="" />
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              height={80}
              loop={false}
              navigation
              modules={[Navigation]}
              style={{ padding: '0 40px' }}>
              {Array.from({ length: 4 }).map((item, i) => {
                const index = i + 1;
                let itemClassName = styles.machItem;
                if (index === machActive) {
                  itemClassName += ' ' + styles.machItemActive;
                }
                return (
                  <SwiperSlide key={index} style={{ width: 180 }}>
                    <div
                      className={itemClassName}
                      onClick={() => {
                        setMachActive(index);
                      }}>
                      <img src={`/saturn-product/se110r-cs/${index}.jpg`} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className={styles.machInfo}>
            <div className={styles.machTitle}>土星云边缘计算微服务器SE110R-CS</div>
            <div className={styles.machDesc}>
              土星云边缘计算微服务器SE110R-CS是一款符合商业控制标准的边缘服务器，采用了国产瑞芯微RK3588
              AI处理器，拥有
              6TOPS(INT8)的AI算力，支持多种AI训练框架和推理框架以及8K视频编解码能力，具有高性能、低功耗、高可靠，高精度等特点。同时，为服务器配备了丰富的接口，包括USB、HDMI、以太网、CAN、UART等，以满足在复杂的商业环境下的运行需求。
            </div>
            <div className={styles.machOperate}>
              <a
                href="/saturn-product/se110r-cs/doc.pdf"
                download="土星云边缘计算微服务器-SE110R-CS商业版产品规格书">
                <Button type="primary" style={{ marginRight: 20 }}>
                  资料下载
                </Button>
              </a>
              <Button
                type="outline"
                onClick={() => {
                  contactUsModalShow();
                }}>
                购买咨询
              </Button>
            </div>
          </div>
        </div>
      </SectionWrap>
      <SectionWrap title="产品特性" background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          {NICE_LIST.map((item, index) => {
            return (
              <div className={styles.niceItem} key={index}>
                <img className={styles.niceIcon} src={`/images/cloud-private-nice-5.png`} alt="" />
                <div className={styles.niceTitle}>{item.title}</div>
                <div className={styles.niceDesc}>{item.desc}</div>
              </div>
            );
          })}
          {/* <div className={styles.niceItem} style={{ visibility: 'hidden' }}></div> */}
        </div>
      </SectionWrap>
      <SectionWrap title="技术规格">
        <table className={cls(styles.normsTable, styles.type2)}>
          <colgroup>
            <col width={160} />
          </colgroup>

          <tbody>
            <tr>
              <td>产品型号</td>
              <td>SE110R-CS-A （商业基础版）</td>
              <td>SE110R-CS-B （商业版含万兆及SDI接口）</td>
            </tr>
            <tr>
              <td>RK3588核心模块</td>
              <td colSpan={2}>
                CPU处理器 l 八核64位（4*Cortex-A76+4*Cortex-A55） Up to 2.4GHZ GPU资 源 l ARM
                Mali-G610 MP4四核GPU, 支持 OpenGL ES3.2 / OpenCL 2.2 / Vulkan1.1, 450 GFLO NPU算 力
                l 6TOPS INT8 l 支持INT4/INT8/INT16混合运算
              </td>
            </tr>
            <tr>
              <td>视频编解码</td>
              <td colSpan={2}>
                l 支持8K@60fps H.265/VP9/AVS2硬件解码 l 支持8K@30fps H.264 AVC/MVC硬件解码 l 支持
                4K@60fps AV1硬件解码 l 支持1080P@60fps MPEG-2/-1/VC-1/VP8硬件解码 l 支持 8K@30fps
                H.265 / H.264硬件编码
              </td>
            </tr>
            <tr>
              <td>内存</td>
              <td colSpan={2}>16GB LPDDR4X</td>
            </tr>
            <tr>
              <td>存储</td>
              <td colSpan={2}>1x eMMC 64GB，1x M.2 NVMe(选配)</td>
            </tr>
            <tr>
              <td>通信</td>
              <td colSpan={2}>1x WiFi(选配)，1x 5G 模块(选配)</td>
            </tr>
            <tr>
              <td>I/O扩展 （前置）</td>
              <td>
                1x 1Gbps RJ45， 2x RS232/422/485， 1x HDMI_TX， 1x HDMI_RX， 1x CAN， 16x GPIO
              </td>
              <td>
                1x 1Gbps RJ45， 2x RS232/422/485， 1x HDMI_TX， 1x HDMI_RX， 1x CAN， 16x GPIO， 1x
                10Gbps， 1x SDI
              </td>
            </tr>
            <tr>
              <td>I/O扩展 （后置）</td>
              <td colSpan={2}>
                2x USB3.0，2x USB2.0，1x RS232（调试），1x 1Gbps RJ45 ， 1x SPEAKER，1x MIC
              </td>
            </tr>
            <tr>
              <td>电源</td>
              <td colSpan={2}>典型DC12V，支持宽压9-36V输入</td>
            </tr>
            <tr>
              <td>工作温度</td>
              <td colSpan={2}>0℃~50℃</td>
            </tr>
            <tr>
              <td>工作湿度</td>
              <td colSpan={2}>湿度：5%~95%（非凝结）</td>
            </tr>
            <tr>
              <td>存储温度</td>
              <td colSpan={2}>-20℃~+85℃</td>
            </tr>
            <tr>
              <td>防护设计</td>
              <td colSpan={2}>防振、防尘和防水（IP54）、防霉菌、防潮湿、防盐雾、抗电磁干扰</td>
            </tr>
            <tr>
              <td>安装</td>
              <td colSpan={2}>支持壁挂/VESA/机架安装</td>
            </tr>
            {/* <tr>
              <td>认证</td>
              <td colSpan={2}>3C/节能</td>
            </tr> */}
            <tr>
              <td>尺寸</td>
              <td colSpan={2}>180mm*175mm*94mm</td>
            </tr>
            <tr>
              <td>功耗</td>
              <td colSpan={2}>＜20W</td>
            </tr>
            <tr>
              <td>操作系统</td>
              <td colSpan={2}>Ubuntu操作系统</td>
            </tr>
            <tr>
              <td>重量</td>
              <td colSpan={2}>&lt;3kg</td>
            </tr>
            <tr>
              <td>其他</td>
              <td colSpan={2}>断电保护，5000米高海拔适用</td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="私有云" />;
}
